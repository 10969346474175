<template>
  <router-link
    :to="{ name: link }"
    class="career-button"
    :class="{
      'animate__animated animate__shakeX ': animate,
    }"
  >
    <b-icon icon="briefcase-fill" class="career-button__icon"></b-icon>
    <span class="career-button__text">
      {{ $store.state.statics.Carier }}
    </span>
  </router-link>
</template>
<script>
export default {
  data() {
    return { animate: false };
  },
  created() {
    setInterval(() => {
      this.animate = true;
      setTimeout(() => {
        this.animate = false;
      }, 1000);
    }, 5000);
  },
  computed: {
    link() {
      if(this.$store.state.lang==='de') {
        return "get-employee"
      }
      return "work"
    }
  }
};
</script>
