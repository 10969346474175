<template>
  <div class="header-right">
    <div class="header-right__lang">
      <b-dropdown
        :text="active.toUpperCase()"
        class="lang-dropdown"
        toggle-class="lang-dropdown__button"
        no-caret
        size="sm"
        menu-class="lang-dropdown__menu"
      >
        <b-dropdown-item
          href="#"
          v-for="(item, index) in languages"
          :key="index"
          @click="changeLang(index)"
          :disabled="item.id == active"
          >{{ item.text }}</b-dropdown-item
        >
      </b-dropdown>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      languages: [
        {
          id: "az",
          text: "AZ",
        },
        {
          id: "de",
          text: "DE",
        },
        {
          id: "en",
          text: "EN",
        },
        {
          id: "ru",
          text: "RU",
        },
        {
          id: "tr",
          text: "TR",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      active: (state) => state.lang,
    }),
  },
  methods: {
    changeLang(e) {
      this.$store.commit("setLangManual", this.languages[e].id);
    },
  },
};
</script>