<template>
  <div class="col-lg-4 col-md-6 mb-4">
    <div class="offer-item" :class="theme">
      <div class="offer-item__header">
        <div class="offer-item__header-inner">
          <div v-html="icon" class="offer-item__icon"></div>
          <div class="offer-item__animation"></div>
        </div>
      </div>
      <h5 class="offer-item__title">
        {{ offer.title }}
      </h5>
      <p class="offer-item__text editor" v-html="offer.description">
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    offer: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
    icon: {
      required: true,
      type: String,
    },
  },
  computed: {
    theme() {
      let i = this.index;
      if (i == 0 || i == 3) {
        return "offer-item--red";
      }
      if (i == 1 || i == 4) {
        return "offer-item--yellow";
      }
      return "offer-item--black";
    },
  },
};
</script>