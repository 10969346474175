import Home from "../views/home";
export default [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "about" */ "../views/about"),
  },
  {
    path: "/career",
    name: "career",
    component: () => import(/* webpackChunkName: "career" */ "../views/career"),
    // redirect: "/career/work",
    children: [
      {
        path: "vacancy",
        name: "vacancy",
        component: () =>
          import(/* webpackChunkName: "vacancy" */ "../views/vacancy"),
      },
      {
        path: "work",
        name: "work",
        component: () => import(/* webpackChunkName: "work" */ "../views/work"),
      },
    ],
  },
  {
    path: "/services",
    name: "services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/services"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/contact"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import(/* webpackChunkName: "faq" */ "../views/faq"),
  },
  {
    path: "/get-employee",
    name: "get-employee",
    component: () =>
      import(/* webpackChunkName: "get-employee" */ "../views/get-employee"),
    meta: { redirect: "other" },
  },
  {
    path: "/partners",
    name: "partners",
    component: () =>
      import(/* webpackChunkName: "partners" */ "../views/partners"),
  },
  {
    path: "/nurses",
    name: "nurses",
    component: () =>
      import(/* webpackChunkName: "nurses" */ "../views/nurses"),
  },
  {
    path: "*",
    redirect: "/",
  },
];
