import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    var t = to.name;
    var f = from.name;
    if (t == "vacancy" || t == "work") {
      if (f == "vacancy" || f == "work") {
        return {};
      }
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let lang = localStorage.getItem("igaplus-client-language");
  if (to.name === "career") {
    if (lang === "de") {
      next({ name: "vacancy" });
    } else {
      next({ name: "work" });
    }
  }
  if (to.name === "work"||to.name === "vacancy") {
    if (lang === "de") {
      next({ name: "home" });
    } else {
      next();
    }
  }
  if (to.name === "get-employee"||to.name==='nurses') {
    if (lang !== "de") {
      next({ name: "home" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
