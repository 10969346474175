import axios from "axios";
import Faq from "../../adapters/faq-adapter";
import store from "./../index";

export default {
  namespaced: true,
  state: {
    faqs: [],
  },

  mutations: {
    setFaqs(state, payload) {
      state.faqs = payload;
    },
  },
  actions: {
    getFaqs({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/home/benefits`)
        .then((response) => {
          const res = response.data.data.benefits;
          let lang = store.state.lang;
          commit(
            "setFaqs",
            res.map((i) => {
              return new Faq(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
