<template>
  <footer class="footer">
    <div class="footer__first">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <a href="./index.html" class="footer__logo"
              ><img src="@/assets/images/logo.svg"
            /></a>
          </div>
          <div class="col-6">
            <div class="footer__socials">
              <Socials />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__second">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="footer__copyright text-left">
              {{ date }} | © igaplus.de
            </div>
          </div>
          <div class="col-6">
            <div class="footer__phone text-right">
              <b-icon icon="telephone-fill" />
              <span>
                {{ $store.state.contact.contacts.phone }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import Socials from "./Socials.vue";
export default {
  components: { Socials },
  computed: {
    date() {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>