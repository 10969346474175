<template>
  <section class="video-section" id="vss">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0" v-if="video">
          <div class="video-section__container">
            <img :src="video.image" class="video-section__image" />
            <div class="video-section__content">
              <div class="w-100">
                <div class="video-section__icon" @click="showVideo">
                  <b-icon icon="play-fill"></b-icon>
                </div>
                <div class="video-section__title">
                  <SectionTitle
                    :title="video.title"
                    :size="5"
                    class="mb-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="video-details" hide-header hide-footer centered size="xl">
      <div class="popup details-modal video-details-modal">
        <div class="row">
          <div class="col-12" v-if="video">
            <video
              :src="video.video"
              controls
              class="video-details-modal__video"
              ref="videoElement"
             
              autoplay
            ></video>

          </div>
        </div>
        <div class="popup__close" @click="$bvModal.hide('video-details')">
          <b-icon :icon="'x'"></b-icon>
        </div>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { mapState } from "vuex";
import SectionTitle from "./../shared/SectionTitle";

export default {
  computed: {
    ...mapState({
      video: (state) => state.video.video,
    }),
    playing() {
      return !this.paused;
    },
  },
  methods: {
    showVideo() {
      this.$bvModal.show("video-details");
    },
  },
  components: {
    SectionTitle,
  },
};
</script>