import axios from "axios";
import Employee from "../../adapters/employee-adapter";
import store from "./../index";

export default {
  namespaced: true,
  state: {
    employees: [],
  },
  getters: {
    getChief(state) {
      let c = state.employees.filter((i) => {
        return i.isChief === true;
      });
      return c[0];
    },
    getEmployees(state) {
      return state.employees.filter((i) => {
        return i.isChief !== true;
      });
    },
  },
  mutations: {
    setEmployees(state, payload) {
      state.employees = payload;
    },
  },
  actions: {
    getEmployees({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/home/employees`)
        .then((response) => {
          const res = response.data.data.employees;
          let lang = store.state.lang;

          commit(
            "setEmployees",
            res.map((i) => {
              return new Employee(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
