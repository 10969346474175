export default class Service {
  constructor(item, lang) {
    this._setId(item);
    this._setImage(item, lang);
    this._setDescription(item, lang);
    this._setTitle(item.serviceType, lang);
  }

  /**
   * set id
   * @param {id} param0
   */
  _setId({ id }) {
    this.id = id;
  }

  /**
   * set image
   * @param {photoUrl} param0
   */
  _setImage({ photoUrl }) {
    this.image = photoUrl;
  }

  /**
   * set title
   * @param {serviceType} item
   * @param {language} lang
   */
  _setTitle(item, lang) {
    let key = "name_" + lang;
    this.title = item[key];
  }

  /**
   * set description
   * @param {service} item
   * @param {language} lang
   */
  _setDescription(item, lang) {
    let key = "description_" + lang;
    this.description = item[key];
  }
}
