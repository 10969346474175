<template>
  <div class="loader">
    <div class="loader__content">
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>
