export default class Video {
  constructor(item, lang) {
    this._setId(item);
    this._setTitle(item, lang);
    this._setDescription(item, lang);
    this._setImage(item);
    this._setVideo(item);
  }

   /**
   * set id
   * @param {id} param0
   */
    _setId({ id }) {
      this.id = id;
    }
    
  /**
   * set title
   * @param {video} item
   * @param {language} lang
   */
  _setTitle(item, lang) {
    let key = "title_" + lang;
    this.title = item[key];
  }

  /**
   * set description
   * @param {video} item
   * @param {language} lang
   */
  _setDescription(item, lang) {
    let key = "description_" + lang;
    this.description = item[key];
  }

  /**
   * set image
   * @param {image} param0
   */
  _setImage({ photoUrl }) {
    this.image = photoUrl;
  }

  /**
   * set video
   * @param {video} param0
   */
  _setVideo({ videoUrl }) {
    this.video = videoUrl;
  }
}
