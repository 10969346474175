import axios from "axios";
import Video from "../../adapters/video-adapter";
import store from "./../index";

export default {
  namespaced: true,
  state: {
    video: [],
  },

  mutations: {
    setVideo(state, payload) {
      state.video = payload;
    },
  },
  actions: {
    getVideo({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/home/videos`)
        .then((response) => {
          const res = response.data.data.videos[0];
          let lang = store.state.lang;
          commit("setVideo", new Video(res, lang));
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
