import axios from "axios";
import Slider from "../../adapters/slider-adapter";
import store from "./../index";

export default {
  namespaced: true,
  state: {
    sliders: [],
  },

  mutations: {
    setSliders(state, payload) {
      state.sliders = payload;
    },
  },
  actions: {
    getSliders({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/home/carouselphotos`)
        .then((response) => {
          const res = response.data.data.carouselPhotos;
          let lang = store.state.lang;
          commit(
            "setSliders",
            res.map((i) => {
              return new Slider(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
