export default class Feedback {
  constructor(item, lang) {
    this._setId(item);
    this._setDescription(item, lang);
    this._modifyFullname(item, lang);
    this._setAge(item);
  }

   /**
   * set id
   * @param {id} param0
   */
    _setId({ id }) {
      this.id = id;
    }
    
  /**
   * set description
   * @param {feedback} item
   * @param {language} lang
   */
  _setDescription(item, lang) {
    let key = "description_" + lang;
    this.description = item[key];
  }

  /**
   * set age
   * @param {age} param0
   */
  _setAge({ age }) {
    this.age = age;
  }

  /**
   * modify fullname
   * @param {feedback} item
   * @param {language} lang
   */
  _modifyFullname(item, lang) {
    let key1 = "name_" + lang;
    let key2 = "surname_" + lang;
    this.fullname = item[key1] + " " + item[key2];
  }
}
