<template>
  <div class="menu">
    <ul class="menu__container">
      <template v-for="(item, index) in menu">
        <li
          class="menu__item"
          :key="index"
          v-if="index < 6 || $store.state.lang === 'de'"
        >
          <router-link
            :to="{ name: item.link }"
            class="menu__link"
            active-class="menu__link--active"
          >
            {{ $store.state.statics[item.name] }}
          </router-link>
        </li>
      </template>
    </ul>

    <b-icon
      icon="x"
      class="close-icon d-block d-lg-none"
      @click="$emit('menu-close')"
    ></b-icon>
    <header-right class="d-none d-lg-flex" />
  </div>
</template>
<script>
import HeaderRight from "./HeaderRight.vue";
export default {
  components: { HeaderRight },

  computed: {
    menu() {
      let lang = this.$store.state.lang;
      if (lang === "de") {
        return [
          {
            name: "WhoAreWe",
            link: "about",
          },
          {
            name: "AboutTheProgram",
            link: "services",
          },
          {
            name: "GetNurse",
            link: "get-employee",
          },
          {
            name: "Carier",
            link: "nurses",
          },
          {
            name: "Partner",
            link: "partners",
          },
          {
            name: "Contact",
            link: "contact",
          },
          {
            name: "Benefit",
            link: "faq",
          },
        ];
      } else {
        return [
          {
            name: "WhoAreWe",
            link: "about",
          },
          {
            name: "AboutTheProgram",
            link: "services",
          },
          {
            name: "Carier",
            link: "career",
          },
          {
            name: "Partner",
            link: "partners",
          },
          {
            name: "Contact",
            link: "contact",
          },
          {
            name: "Benefit",
            link: "faq",
          },
        ];
      }
    },
  },
};
</script>
