import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueToast from "vue-toast-notification";
import "animate.css";
import "vue-toast-notification/dist/theme-sugar.css";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import Vuelidate from "vuelidate";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import InputRow from "@/components/shared/InputRow";
import TextareaRow from "@/components/shared/TextareaRow";
import RadioRow from "@/components/shared/RadioRow";
import CheckboxRow from "@/components/shared/CheckboxRow";
import FileInput from "@/components/shared/FileInput";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueToast);
Vue.use(VueSlickCarousel);
Vue.use(Vuelidate);

Vue.component("file-input", FileInput);
Vue.component("checkbox-row", CheckboxRow);
Vue.component("radio-row", RadioRow);
Vue.component("input-row", InputRow);
Vue.component("textarea-row", TextareaRow);

axios.defaults.baseURL = "https://api.igaplus.de/api/v1";
// axios.defaults.baseURL = "http://test.igaplus.de/api/v1";

axios.interceptors.response.use(
  (response) => {
    store.commit("setLoader", false);
    return response;
  },
  (error) => {
    const {
      response: { status, data },
    } = error;

    console.log("Error: ", error.response);
    let errMessage = "Xəta baş verdi";

    if (status === 400) {
      if (data.message) {
        errMessage = data.message;
      } else {
        errMessage = data.ValidationErrors[0].Message;
      }
    }
    if (status === 404) {
      if (data.message) {
        errMessage = data.message;
      }
    }
    Vue.$toast.error(errMessage);
    store.commit("setLoader", false);
  }
);

axios.defaults.params = {};
axios.interceptors.request.use(function (config) {
    config.params['culture'] = localStorage.getItem("igaplus-client-language");
    return config;
}, function (error) {
    return Promise.reject(error);
});

import "./assets/styles/main.scss";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
