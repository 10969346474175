import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Level from "../adapters/level-adapter";
import Institution from "../adapters/institution-adapter";
import Profession from "../adapters/profession-adapter";
import WeekDay from "../adapters/week-day-adapter";
// Modules
import slider from "./modules/slider";
import offers from "./modules/offers";
import video from "./modules/video";
import feedbacks from "./modules/feedbacks";
import pageCover from "./modules/page-cover";
import about from "./modules/about";
import services from "./modules/services";
import partners from "./modules/partners";
import faq from "./modules/faq";
import contact from "./modules/contact";
import vacancies from "./modules/vacancies";
import work from "./modules/work";
import getEmployee from "./modules/get-employee";
import nurses from "./modules/nurses";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loader: false,
    statics: {},
    lang: "az",
    levels: [],
    professions: [],
    institutions: [],
    weekDays: [],
    acceptCookies: false,
    terms: "https://api.igaplus.de/api/v1/file/GetFile/PSyz4oYS9xM00XVQi7I9fg%3d%3d"
  },
  getters: {
    getLoader(state) {
      return state.loader;
    }
  },
  mutations: {
    setLoader(state, payload) {
      state.loader = payload;
    },
    setStatisc(state, payload) {
      let obj = {};
      for (const i of payload) {
        obj[i.key] = i.value;
      }
      state.statics = obj;
    },
    setLevels(state, payload) {
      state.levels = payload;
    },
    setProfessions(state, payload) {
      state.professions = payload;
    },
    setInstitutions(state, payload) {
      state.institutions = payload;
    },
    setWeekDays(state, payload) {
      state.weekDays = payload;
    },
    setLang(state, payload) {
      localStorage.setItem("igaplus-client-language", payload);
      state.lang = payload;
    },
    setLangManual(state, payload) {
      localStorage.setItem("igaplus-client-language", payload);
      state.lang = payload;
      location.reload();
    },
    setCookie(state, payload) {
      state.acceptCookies = payload;
    }

  },
  actions: {
    getStatics({ commit }) {
      commit("setLoader", true);
      return axios
        .get(`/translation/gethome`)
        .then((response) => {
          const res = response.data.translations;
          commit("setStatisc", res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getLevels({ state, commit }) {
      commit("setLoader", true);
      return axios
        .get(`/home/languageLevels`)
        .then((response) => {
          const res = response.data.data.languageLevels;
          commit(
            "setLevels",
            res.map((i) => {
              return new Level(i, state.lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getProfessions({ state, commit }) {
      commit("setLoader", true);
      return axios
        .get(`/home/professions`)
        .then((response) => {
          const res = response.data.data.professions;
          commit(
            "setProfessions",
            res.map((i) => {
              return new Profession(i, state.lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getInstitutions({ state, commit }) {
      commit("setLoader", true);
      return axios
        .get(`/home/institutions`)
        .then((response) => {
          const res = response.data.data.institutions;
          commit(
            "setInstitutions",
            res.map((i) => {
              return new Institution(i, state.lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getWeekDays({ state, commit }) {
      commit("setLoader", true);
      return axios
        .get(`/home/workDaysOfTheWeek`)
        .then((response) => {
          const res = response.data.data.DaysOfTheWeeks;
          commit(
            "setWeekDays",
            res.map((i) => {
              return new WeekDay(i, state.lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getLanguage({ commit }) {
      let l = localStorage.getItem("igaplus-client-language");
      if (l) {
        commit("setLang", l);
      } else {
        commit("setLang", "az");
      }
      return true;
    },
    getCookieStatus({ commit }) {
      let c = localStorage.getItem("igaplus-accept-cookie");
      if (c) {
        commit("setCookie", true);
      }
      return c;
    }
  },
  modules: {
    slider,
    offers,
    video,
    feedbacks,
    pageCover,
    about,
    services,
    partners,
    faq,
    contact,
    vacancies,
    work,
    getEmployee,
    nurses
  }
});
