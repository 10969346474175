<template>
  <transition
      leave-active-class="animate__animated animate__fadeOutDown animate__faster"
      mode="out-in"
  >
    <div v-if="!cookie" class="cookie">
      <div class="cookie__text mr-5" v-html="cookieText">

      </div>
      <button class="button button--main" @click.prevent="acceptCookies">
        {{ this.$store.state.statics.Accept }}
      </button>
    </div>
  </transition>
</template>
<script>


import {mapState} from 'vuex';

export default {
  name: 'Cookies',
  methods: {
    acceptCookies() {
      this.$store.commit('setCookie', true);
      localStorage.setItem('igaplus-accept-cookie', true);
    }
  },
  computed: {
    ...mapState({
      cookie: state => state.acceptCookies
    }),
    cookieText() {
      let lang = this.$store.state.lang;
      if (lang === 'de')
        return `
      Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, würden wir gerne Cookies zu Analysezwecken einsetzen. Weitere Informationen hierzu finden Sie in unserer .
        <a href="https://api.igaplus.de/api/v1/file/GetFile/PSyz4oYS9xM00XVQi7I9fg%3d%3d" target="_blank">
          Datenschutzerklärung
        </a>
      `;
      if (lang === 'en') {
        return `
     In order to design our website optimally for you and to be able to improve it continuously, we would like to use cookies for analysis purposes. You can find further information on this in our
        <a href="https://api.igaplus.de/api/v1/file/GetFile/PSyz4oYS9xM00XVQi7I9fg%3d%3d" target="_blank">
          data protection declaration
        </a>
      `;
      }
      if (lang === 'ru') {
        return `
     Оставаясь на  igaplus.de , вы принимаете <a href="https://api.igaplus.de/api/v1/file/GetFile/PSyz4oYS9xM00XVQi7I9fg%3d%3d" target="_blank">пользовательское соглашение</a>
      `;
      }
      if (lang === 'tr') {
        return `
     Sitemizde size iyi hizmet sunmak için <a href="https://api.igaplus.de/api/v1/file/GetFile/PSyz4oYS9xM00XVQi7I9fg%3d%3d" target="_blank">çerezleri</a> kullanıyoruz.
Bu mesajı kabul ederek çerezlerimizi kabul ettiğinizi onaylamış olursunuz.
      `;
      }
      return `Saytımızda sizlərə yaxşı xidmət təmin etmək üçün <a href="https://api.igaplus.de/api/v1/file/GetFile/PSyz4oYS9xM00XVQi7I9fg%3d%3d" target="_blank">çərəzlərdən</a> istifadə edirik.
Bu mesajla razılaşaraq, bizim çərəzlərimizi qəbul etdiyinizi təsdiq edirsiz.`;
    }

  }

};
</script>
