import Vue from "vue";
import axios from "axios";
import store from "./../index";

export default {
  namespaced: true,
  actions: {
    send(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post(`/home/applyForEmployee`, payload)
        .then((response) => {
          if (response && response.status === 201) {
            Vue.$toast.success(response.data.message);
            return true;
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
};
