<template>
  <div id="app">
    <Header/>
    <main class="main" :class="{ 'main--homepage': isHomePage }">
      <transition
          enter-active-class="animate__animated animate__fadeInRight animate__faster"
          leave-active-class="animate__animated animate__fadeOutLeft animate__faster"
          mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </main>
    <Footer/>
    <CareerButton/>
    <Loader v-show="$store.getters.getLoader"/>
    <Cookies/>
    <b-modal id="language-modal" hide-header hide-footer centered scrollable-y no-close-on-backdrop>
      <div class="popup details-modal language-modal">
        <div class="row">
          <div class="col-12">
            <ul class='language__list'>
              <li v-for='(item,index) in languages' :key='index' class='language__list-item' @click='changeLang(index)'>
                {{ item.text }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Header from './components/layout/Header';
import Loader from './components/layout/Loader';
import Footer from './components/layout/Footer';
import CareerButton from './components/layout/CareerButton';
import Cookies from './components/layout/CookieAccept';
import {mapState} from 'vuex';

export default {
  components: {
    Cookies,
    Header,
    Footer,
    CareerButton,
    Loader
  },
  data() {
    return {
      languages: [
        {
          id: 'az',
          text: 'Azərbaycan',
        },
        {
          id: 'de',
          text: 'Deutsch',
        },
        {
          id: 'en',
          text: 'English',
        },
        {
          id: 'ru',
          text: 'Русский',
        },
        {
          id: 'tr',
          text: 'Türkçe',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      active: (state) => state.lang,
    }),
    isHomePage() {
      if (this.$route.name == 'home') {
        return true;
      }
      return false;
    }
  },
  methods: {
    fetchInitialData() {
      this.$store.dispatch('getStatics');
      this.$store.dispatch('contact/getContacts');
      this.$store.dispatch('pageCover/getCovers');
    },
    changeLang(e) {
      this.$bvModal.hide('language-modal');
      localStorage.setItem('igaplus-client-language-selected', true)
      this.$store.commit('setLangManual', this.languages[e].id);
    },
  },
  created() {
    this.$bvModal.show('language-modal');
    this.$store.dispatch('getCookieStatus').then(() => {
      this.$store.dispatch('getLanguage').then((res) => {
        if (res) {
          this.fetchInitialData();
        }
      });
    });
  },
  mounted() {
    if (!localStorage.getItem('igaplus-client-language-selected')) {
      this.$bvModal.show('language-modal');
    }
  }
};
</script>
