export default class Faq {
  constructor(item, lang) {
    this._setId(item);
    this._setPhone(item, lang);
    this._setAddress(item, lang);
    this._setEmail(item, lang);
  }

  /**
   * set id
   * @param {id} param0
   */
  _setId({ id }) {
    this.id = id;
  }

  /**
   * set phone
   * @param {contact} item
   * @param {language} lang
   */
  _setPhone(item, lang) {
    let key = "phone_" + lang;
    this.phone = item[key];
  }

  /**
   * set address
   * @param {contact} item
   * @param {language} lang
   */
  _setAddress(item, lang) {
    let key = "address_" + lang;
    this.address = item[key];
  }

  /**
   * set email
   * @param {contact} item
   * @param {language} lang
   */
  _setEmail(item, lang) {
    let key = "email_" + lang;
    this.email = item[key];
  }
}
