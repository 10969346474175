export default class Employee {
  constructor(item, lang) {
    this._setId(item);
    this._setIsChief(item);
    this._setDescription(item, lang);
    this._modifyFullname(item, lang);
    this._setImage(item);
    this._setPosition(item.position, lang);
  }

  /**
   * set id
   * @param {id} param0
   */
  _setId({ id }) {
    this.id = id;
  }

  /**
   * set isChief
   * @param {isChief} param0
   */
   _setIsChief({ isChief }) {
    this.isChief = isChief;
  }

  /**
   * set description
   * @param {employee} item
   * @param {language} lang
   */
  _setDescription(item, lang) {
    let key = "description_" + lang;
    this.description = item[key];
  }

  /**
   * set position
   * @param {position} item
   * @param {language} lang
   */
  _setPosition(item, lang) {
    let key = "positionName_" + lang;
    this.position = item[key];
  }

  /**
   * set image
   * @param {photoUrl} param0
   */
  _setImage({ photoUrl }) {
    this.image = photoUrl;
  }

  /**
   * modify fullname
   * @param {employee} item
   * @param {language} lang
   */
  _modifyFullname(item, lang) {
    let key1 = "name_" + lang;
    let key2 = "surname_" + lang;
    this.fullname = item[key1] + " " + item[key2];
  }
}
