<template>
  <div class="file-input">
    <input type="file" hidden ref="fileInput" @change="changeInput" />
    <label class="label mb-2">
      {{ label }}
    </label>
    <span class="file-input__content" @click="clickInput">
      <label class="button button--main file-input__button">
        {{ $store.state.statics.Download }}
      </label>
      <input
        type="text"
        class="form-control form-input file-input__input"
        disabled
        v-model="input"
      />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      input: "",
    };
  },
  methods: {
    clickInput() {
      this.$refs.fileInput.click();
    },
    changeInput(event) {
      let fileData = event.target.files[0];
      this.input = fileData.name;

      this.$emit("file", fileData);
    },
  },
};
</script>
