<template>
  <div class="home">
    <Lead />
    <Offer />
    <VideoSection />
    <Feedback />
  </div>
</template>
<script>
import Lead from "@/components/home/Lead.vue";
import Offer from "@/components/home/Offer.vue";
import VideoSection from "@/components/home/VideoSection.vue";
import Feedback from "@/components/home/Feedback.vue";
export default {
  name: "Home",
  components: {
    Lead,
    Feedback,
    VideoSection,
    Offer,
  },
  created() {
    this.$store.dispatch("slider/getSliders");
    this.$store.dispatch("offers/getOffers");
    this.$store.dispatch("video/getVideo");
    this.$store.dispatch("feedbacks/getFeedbacks");
  },
};
</script>