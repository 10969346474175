<template>
  <div class="checkbox-row">
    <label v-if="title" class="checkbox-row__title"> {{ title }}card </label>
    <label
      class="checkbox-row__label"
      v-for="(item, index) in options"
      :key="index"
    >
      <input type="checkbox" class="checkbox-row__input" :name="name" />
      <span class="checkbox-row__text" v-html="item.text"> </span>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      // required: true,
    },
    options: {
      type: Array,
      // required: true,
    },
  },
};
</script>