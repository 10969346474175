import Vue from "vue";
import axios from "axios";
import Vacancy from "../../adapters/vacancy-adapter";
import store from "./../index";

export default {
  namespaced: true,
  state: {
    vacancies: [],
  },

  mutations: {
    setVacancies(state, payload) {
      state.vacancies = payload;
    },
  },
  actions: {
    getVacancies({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/home/vacancies`)
        .then((response) => {
          const res = response.data.data.vacancies;
          let lang = store.state.lang;
          commit(
            "setVacancies",
            res.map((i) => {
              return new Vacancy(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    sendAppeal(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post(`/home/vacancyApply`, payload)
        .then((response) => {
          if (response && response.status === 201) {
            Vue.$toast.success(response.data.message);
            return true;
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
};
