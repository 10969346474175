<template>
  <div class="title">
    <div class="row no-gutters justify-content-center">
      <div :class="colSize">
        <div class="title__text" v-if="title">
          {{ title }}
        </div>
        <div class="title__desc" v-html="desc" v-if="desc"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    desc: {
      type: String,
      required: false,
    },
    size: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    colSize() {
      return "col-lg-" + this.size;
    },
  },
};
</script>
