<template>
  <header class="header" :class="{ 'header--sticky': active || !isHomePage }">
    <div class="header__main">
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-6">
            <router-link :to="{ name: 'home' }" class="header__logo">
              <img src="@/assets/images/logo2.svg" />
            </router-link>
          </div>
          <div class="col-lg-10 col-6">
            <HeaderMenu
              :class="{ 'menu-show': menu }"
              @menu-close="menu = false"
            />
            <div class="mobile-menu d-flex d-lg-none">
              <header-right class="header-right--mobile" />
              <b-icon
                icon="list"
                class="mobile-menu__icon"
                @click="menu = true"
              ></b-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import HeaderMenu from "./HeaderMenu";
import HeaderRight from "./HeaderRight.vue";
export default {
  data() {
    return {
      active: false,
      menu: false,
    };
  },
  watch: {
    $route() {
      this.menu = false;
    },
  },
  components: {
    HeaderMenu,
    HeaderRight,
  },
  computed: {
    isHomePage() {
      if (this.$route.name == "home") {
        return true;
      }
      return false;
    },
  },

  mounted() {
    window.document.onscroll = () => {
      let navBar = document.getElementsByTagName("header")[0];
      if (window.scrollY > navBar.offsetTop + 86) {
        this.active = true;
      } else {
        if (this.$route.name == "home") {
          this.active = false;
        }
      }
    };
  },
};
</script>