<template>
  <div class="lead">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <div class="lead__carousel" v-if="sliders && sliders.length">
            <VueSlickCarousel :arrows="true" @afterChange="changeSlider">
              <div
                class="lead__item"
                v-for="(item, index) in sliders"
                :key="index"
                :style="{ 'background-image': 'url(' + item.image + ')' }"
              >
                <div class="lead__content">
                  <div class="container">
                    <div class="row justify-content-center justify-content-lg-start">
                      <div class="col-lg-6 col-11">
                        <div
                          class="lead__content-header editor"
                          v-html="item.title"
                        ></div>
                        <div class="lead__content-text">
                          {{ item.description }}
                        </div>
                        <div class="lead__buttons">
                          <a
                            :href="item.url"
                            class="button button--large button--main"
                          >
                            {{ $store.state.statics.Details }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="lead__scroll" @click="scrollDown">
                  <b-icon icon="chevron-down" />
                  <div>
                    {{ $store.state.statics.ScrollDown }}
                  </div>
                </div>
              </div>
              <template #prevArrow>
                <div class="prev lead__nav lead__nav--prev" ref="previous">
                  <b-icon icon="chevron-left" />
                  <span class="lead__nav-inner">
                    <img :src="prevImage" />
                  </span>
                </div>
              </template>
              <template #nextArrow>
                <div class="next lead__nav lead__nav--next">
                  <b-icon icon="chevron-right" />
                  <span class="lead__nav-inner">
                    <img :src="nextImage" />
                  </span>
                </div>
              </template>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "Lead",
  data() {
    return {
      currentItem: 0,
    };
  },

  computed: {
    ...mapState({
      sliders: (state) => state.slider.sliders,
    }),
    prevImage() {
      var img = this.currentItem - 1;
      if (this.currentItem == 0) {
        img = this.sliders.length - 1;
      }
      return this.sliders[img].image;
    },
    nextImage() {
      var img = this.currentItem + 1;
      if (this.currentItem == this.sliders.length - 1) {
        img = 0;
      }
      return this.sliders[img].image;
    },
  },
  methods: {
    changed(e) {
      this.currentItem = e.item.index - 2;
    },
    changeSlider(e) {
      this.currentItem = e;
    },
    scrollDown() {
      let pageHeight = window.innerHeight;
      window.scrollTo(0, pageHeight);
    },
  },
  components: {
    VueSlickCarousel,
  },
};
</script>
