<template>
  <div class="radio-row">
    <label v-if="title" class="radio-row__title">
      {{ title }}
    </label>
    <label
      class="radio-row__label"
      v-for="(item, index) in options"
      :key="index"
    >
      <input
        type="radio"
        class="radio-row__input"
        :name="name"
        @input="changeInput"
        ref="inpt"
        :value="item.value"
      />
      <span class="radio-row__text">
        {{ $store.state.statics[item.text] }}
      </span>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    changeInput(e) {
      console.log(this.$refs.inpt,e)
      // this.$emit("input", this.selected);
    },
  },
};
</script>