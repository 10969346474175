<template>
  <section class="feedback">
    <div class="container">
      <SectionTitle
        :title="$store.state.statics.FeedbackTitle"
      />

      <div class="row">
        <div class="col-12" v-if="feedbacks && feedbacks.length">
          <VueSlickCarousel :arrows="true">
            <div
              class="feedback__item"
              v-for="(item, index) in feedbacks"
              :key="index"
            >
              <div class="feedback__text editor" v-html="item.description">
              </div>
              <div class="feedback__user">
                {{ item.fullname }}, {{ item.age }}
              </div>
            </div>
            <template #prevArrow>
              <div class="feedback__nav feedback__nav--prev" ref="previous">
                <b-icon icon="chevron-left" />
              </div>
            </template>
            <template #nextArrow>
              <div class="feedback__nav feedback__nav--next">
                <b-icon icon="chevron-right" />
              </div>
            </template>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import SectionTitle from "@/components/shared/SectionTitle.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      title: {
        text: "We've Worked With",
        desc: "Our 3000+ customers have trusted in our work, don't be hesitate to join our happy customer team. <br/> Polygon will elevate your business website to the next level.",
      },
    };
  },
  computed: {
    ...mapState({
      feedbacks: (state) => state.feedbacks.feedbacks,
    }),
  },

  components: {
    VueSlickCarousel,
    SectionTitle,
  },
};
</script>
