import axios from "axios";
import Service from "../../adapters/service-adapter";
import store from "./../index";

export default {
  namespaced: true,
  state: {
    services: [],
  },

  mutations: {
    setServices(state, payload) {
      state.services = payload;
    },
  },
  actions: {
    getServices({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/home/services`)
        .then((response) => {
          const res = response.data.data.services;
          let lang = store.state.lang;
          commit(
            "setServices",
            res.map((i) => {
              return new Service(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
