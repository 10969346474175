import axios from "axios";
import Offer from "../../adapters/offer-adapter";
import store from "./../index";

export default {
  namespaced: true,
  state: {
    offers: [],
  },

  mutations: {
    setOffers(state, payload) {
      state.offers = payload;
    },
  },
  actions: {
    getOffers({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/home/suggests`)
        .then((response) => {
          const res = response.data.data.suggests;
          let lang = store.state.lang;
          commit(
            "setOffers",
            res.map((i) => {
              return new Offer(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
