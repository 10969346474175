export default class Offer {
  constructor(item, lang) {
    this._setId(item);
    this._setTitle(item.suggestType, lang);
    this._setDescription(item, lang);
  }

   /**
   * set id
   * @param {id} param0
   */
    _setId({ id }) {
      this.id = id;
    }
    
  /**
   * set title
   * @param {offer} item
   * @param {language} lang
   */
  _setTitle(item, lang) {
    let key = "name_" + lang;
    this.title = item[key];
  }

  /**
   * set description
   * @param {offer} item
   * @param {language} lang
   */
  _setDescription(item, lang) {
    let key = "description_" + lang;
    this.description = item[key];
  }

}
