import Vue from "vue";
import axios from "axios";
import Contact from "../../adapters/contact-adapter";
import store from "./../index";

export default {
  namespaced: true,
  state: {
    contacts: {},
  },
  getters: {
    getPhone(state) {
      state.contacts.phone;
    },
  },
  mutations: {
    setContacts(state, payload) {
      state.contacts = payload;
    },
  },
  actions: {
    getContacts({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/home/contact`)
        .then((response) => {
          const res = response.data.data.contacts[0];
          let lang = store.state.lang;
          commit("setContacts", new Contact(res, lang));
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    sendMessage(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post(`/home/contactForm`, payload)
        .then((response) => {
          if (response && response.status === 201) {
            Vue.$toast.success(response.data.message);
            return true;
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
};
