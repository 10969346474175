<template>
  <div class="input-row">
    <label class="input-row__inner">
      <input
        class="form-control input-row__field"
        :type="type"
        placeholder=" "
        :value="value"
        @input="changeInput"
        ref="inpt"
        :disabled="disabled"
      />
      <span class="input-row__label">{{ label }}</span>
    </label>
  </div>
</template>
<script>
export default {
  data() {
    return {
      indent: 0,
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      required: false,
    },
  },
  methods: {
    changeInput() {
      this.$emit("input", this.$refs.inpt.value);
    },
  },
};
</script>
